import React from 'react';

export default function Qanda() {
  return (
    <div className='welcome-text'>
      <div className='welcome-text-paragraph'>
        Check back later for some fun bits of information :D
      </div>
    </div>
  );
}
